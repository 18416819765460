import './style.scss';

import React, { useEffect, useState } from 'react';

import { BellOutlined, LogoutOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import logo from 'dist/images/logo.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Prompt } from 'react-router-dom';

import { DEVICES } from '../../constants';
import { process } from '../../store/autosyn/autosyn.selector';
import { setEditMode } from '../../store/common/common.actions';
import { editModeSelector } from '../../store/common/common.selector';
import { lockManualProcess, updateTheLockState } from '../../store/processBuilder/processbuilder.actions';
import { lockSynjetProcess } from '../../store/synjet/synjet.actions';
import { synjectProcess } from '../../store/synjet/synjet.selector';
import { httpRest } from '../../utils';
import history from '../../utils/history';
import { Avatar, Popup } from '..';

const SELECTED_KEYS = ['/autosyn', '/projects', '/users', '/execution', '/scheduling', '/experiments', '/processes'];

export const Header = ({ path, user }) => {
  const [current, setCurrent] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [value, setPath] = useState(undefined);
  const editMode = useSelector(editModeSelector);
  const currentProcess = useSelector(process);
  const synjetProcess = useSelector(synjectProcess);
  const dispatch = useDispatch();
  useEffect(() => {
    const endIndex = path.indexOf('/', 1);
    const commonPath = endIndex == -1 ? path : path.slice(0, endIndex);
    setCurrent(SELECTED_KEYS.filter(v => commonPath == v));
  }, [path]);
  const tabs = user.permissions || {};
  const roles = user.roles || {};

  const logOut = () => {
    httpRest({
      url: 'auth/logout/',
      method: 'POST',
      data: {
        refresh: window.localStorage.getItem('refresh'),
      },
    }).then(() => {
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('refresh');
      history.push('/login');
    });
  };
  const checkEdit = (event, path) => {
    if (editMode) {
      event.preventDefault();
      setConfirmation(true);
      setPath(path);
    } else if (!path) {
      logOut();
    }
  };

  return (
    <>
      <header className="main-header">
        <img className="title" src={logo} />
        <Menu selectedKeys={current} mode="horizontal">
          {!!user.integrations && (
            <Menu.Item key="/chemdasl">
              <Link
                to={`/chemdasl`}
                onClick={event => {
                  checkEdit(event, '/chemdasl');
                }}
              >
                ChemDASL
              </Link>
            </Menu.Item>
          )}
          {!!user.integrations && (
            <Menu.Item key="/synroute">
              <Link
                to="/synroute"
                onClick={event => {
                  checkEdit(event, '/synroute');
                }}
              >
                SynRoute
              </Link>
            </Menu.Item>
          )}
          {tabs.process?.view_process && (
            <Menu.Item key="/processes">
              <Link
                to="/processes"
                onClick={event => {
                  checkEdit(event, '/processes');
                }}
              >
                Processes
              </Link>
            </Menu.Item>
          )}
          {tabs.process?.view_process && (
            <Menu.Item key="/experiments">
              <Link
                to="/experiments"
                onClick={event => {
                  checkEdit(event, '/experiments');
                }}
              >
                Experiments
              </Link>
            </Menu.Item>
          )}
          {tabs.scheduling?.view_devicetimeslot && (
            <Menu.Item key="/scheduling">
              <Link
                to="/scheduling"
                onClick={event => {
                  checkEdit(event, '/scheduling');
                }}
              >
                Scheduling
              </Link>
            </Menu.Item>
          )}
          {tabs.execution?.view_experiment &&
            roles.some(i => i === 'System Administrator' || i === 'Trained Operator') && (
              <Menu.Item key="/execution">
                <Link
                  to="/execution"
                  onClick={event => {
                    checkEdit(event, '/execution');
                  }}
                >
                  Execution
                </Link>
              </Menu.Item>
            )}
          {tabs.project?.view_project && (
            <Menu.Item key="/projects">
              <Link
                to="/projects"
                onClick={event => {
                  checkEdit(event, '/projects');
                }}
              >
                Projects
              </Link>
            </Menu.Item>
          )}
          {tabs.user?.view_user && (
            <Menu.Item key="/users">
              <Link
                to="/users"
                onClick={event => {
                  checkEdit(event, '/users');
                }}
              >
                Users
              </Link>
            </Menu.Item>
          )}
        </Menu>
        <div className="right-wrapper">
          <div className="avatar-wrapper">
            {!!user.firstName && <Avatar>{`${user.firstName} ${user.lastName}`.match(/\b(\w)/g)?.join('')}</Avatar>}
            <div className="text">
              {user.firstName} {user.lastName}
            </div>
          </div>
          <div
            className="log-out"
            onClick={event => {
              checkEdit(event);
            }}
          >
            <LogoutOutlined />
            <span className="text">Log out</span>
          </div>
        </div>
      </header>
      <Popup
        open={confirmation}
        title="Leave the page?"
        handleSubmit={() => {
          if (currentProcess?.process?.device === DEVICES.LAB) {
            dispatch(lockManualProcess(currentProcess?.process?.processLock?.uuid, 'unlocked'));
          } else if (currentProcess?.process?.device === DEVICES.AUTOSYN) {
            updateTheLockState({
              uuid: currentProcess?.process?.processLock?.uuid,
              state: 'unlocked',
            });
          } else if (synjetProcess?.process?.device === DEVICES.SYNJET) {
            dispatch(lockSynjetProcess(synjetProcess?.process?.processLock?.uuid, 'unlocked'));
          }
          dispatch(setEditMode(false));
          setConfirmation(false);
          if (value) {
            history.push(value);
          } else {
            logOut();
          }
        }}
        handleCancel={() => {
          setConfirmation(false);
        }}
        textSubmit="Confirm"
      >
        <span>Changes you made may not be saved.</span>
      </Popup>
      {editMode && (
        <Prompt
          message={(location, action, a, vb, c) => {
            if (action === 'POP' && editMode) {
              setConfirmation(true);
              setPath(`${location.pathname}${location.search}`);
              return false;
            }
          }}
        />
      )}
    </>
  );
};
