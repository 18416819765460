import axios from 'axios';
import { GraphQLClient } from 'graphql-request';
import moment from 'moment-timezone';
import qs from 'qs';

import history from './history';

export const http = (query, controller, ...rest) => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
    Timezone: moment.tz.guess(),
    Authorization: `Bearer ${window.localStorage.getItem('token')}`,
  };

  const signal = controller?.signal;

  const client = new GraphQLClient('/api/graphql/', { headers, signal });
  localStorage.setItem('pdfRequestCount', +localStorage.getItem('pdfRequestCount') + 1);
  return client
    .request(query)
    .then(data => {
      localStorage.setItem('pdfRequestCount', +localStorage.getItem('pdfRequestCount') - 1);
      return data;
    })
    .catch(e => {
      localStorage.setItem('pdfRequestCount', +localStorage.getItem('pdfRequestCount') - 1);
      // console.log(e)
      if (e.message.indexOf('Code: 403') > 0 || e.message.indexOf('Code: 401') > 0) {
        localStorage.removeItem('token');
        history.push('/login');
      } else if (e?.message) {
        return e?.message;
      }
      throw e?.response || 'error';
    });
};

export const httpRest = ({ url, method = 'get', data, hideError = false, ...rest }) => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
  };
  const auth = localStorage.getItem('token');
  if (auth) {
    headers.Authorization = `Bearer ${auth}`;
  }
  const other = { ...rest };
  if (data && (method === 'get' || method === 'delete')) {
    other.params = data;
    other.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'comma' });
    };
  } else {
    other.data = data;
  }

  return axios({
    url: `/api/${url}`,
    method,
    headers,
    ...other,
  })
    .then(({ data }) => {
      if (data.access) {
        window.localStorage.setItem('token', data.access);
        window.localStorage.setItem('refresh', data.refresh);
      }
      return data;
    })
    .catch(e => {
      if (e.response.status === 403 && !e.response.data.hasOwnProperty('errorCode')) {
        localStorage.removeItem('token');
        history.push('/login');
      }
      if (e.response.status === 404) {
        history.push('/404');
      }
      if (
        e.response.status === 400 ||
        e.response.status === 409 ||
        (e.response.status === 403 && e.response.data.hasOwnProperty('errorCode'))
      ) {
        if (!hideError) {
          // messageHandler.error(e.response.data.message)
        }
      }
      return Promise.reject(e.response.data || e.response);
    });
};

export const httpSynMolDB = url => {
  const headers = {
    'Access-Control-Allow-Origin': '*',
  };

  const auth = localStorage.getItem('token');

  if (auth) {
    headers.Authorization = `Bearer ${auth}`;
  }

  return fetch(`/database/synmoldb/${url}`, {
    headers: headers,
  }).then(resp => resp.json());
};
