import React, { useEffect, useState } from 'react';
import './style.scss';
import { PreviewSmiles } from 'components';
import { Molecule } from 'openchemlib';
import { Alert, Button, Form, Input, Radio, Spin, Tooltip } from 'antd';
import { Select } from '../../components/Common';

const { TextArea } = Input;
const moleculeInputTypeOptions = [
  {
    label: 'Molblock',
    value: 'molblock',
  },
  {
    label: 'SMILES',
    value: 'smiles',
  },
];

export const ChemDASL = () => {
  const CHEMDASL_BASE = '/application/chemdasl/'; // instead of: https://synfini-design.cse.sri.com/
  const CHEMDASL_BACKEND_URL = CHEMDASL_BASE + 'api/v1/models';

  var [models, setModels] = useState([]);
  var [modelOptions, setModelOptions] = useState([]);
  var [selectedModel, updateSelectedModel] = useState({});
  var [isLoadingData, updateIsLoadingData] = useState(false);
  var [moleculeInput, updateMoleculeInput] = useState('');

  // track the molecule type automatically or upon user input
  var [moleculeType, updateMoleculeType] = useState('molblock');
  var [manualMoleculeType, updateManualMoleculeType] = useState('');

  var [errorMessage, setErrorMessage] = useState('');

  const [chemdaslData, setChemdaslData] = useState({
    models: [],
    molblock: '',
    mol: '',
    smiles: '',
    predictions: {},
  });

  const onMoleculeInputChange = ({ target: moleculeInputTextArea }) => {
    // this will not be necessary once we upgrade to a newer version of
    // ant and react, then it's possible to just link the textarea
    // using a ref: https://stackoverflow.com/a/74574002
    if (moleculeInputTextArea.value) {
      const moleculeInput = moleculeInputTextArea.value;
      updateMoleculeInput(moleculeInput);
      evaluateInputType(moleculeInput);
    } else {
      updateMoleculeInput('');
    }
  };

  const evaluateInputType = moleculeInput => {
    if (!manualMoleculeType && moleculeInput) {
      let numberOfLines = moleculeInput.split(/\r\n|\r|\n/).length;
      let moleculeDataInputType = numberOfLines > 3 ? 'molblock' : 'smiles';
      updateMoleculeType(moleculeDataInputType);
    }
  };

  const selectMoleculeType = ({ target: selectedMoleculeType }) => {
    if (selectedMoleculeType && selectedMoleculeType.value) {
      updateManualMoleculeType(selectedMoleculeType.value);
      updateMoleculeType(selectedMoleculeType.value);
    }
  };

  const clearData = () => {
    console.log('clearing data...');

    updateManualMoleculeType('');

    setChemdaslData({
      models: [],
      molblock: '',
      mol: '',
      smiles: '',
      predictions: {},
    });

    setErrorMessage('');
  };

  // authentication
  const getAuthHeader = () => {
    const headers = {};

    const auth = localStorage.getItem('token');

    if (auth) {
      headers.Authorization = `Bearer ${auth}`;
    }

    return headers;
  };

  // Load Models
  const fetchModels = () => {
    updateIsLoadingData(true);
    fetch(CHEMDASL_BACKEND_URL, { headers: getAuthHeader() })
      .then(response => {
        return response.json();
      })
      .then(data => {
        setModels(data);
        updateModelOptions(data);
        updateSelectedModel(data[0]);
      })
      .finally(() => {
        updateIsLoadingData(false);
      });
  };

  useEffect(() => {
    fetchModels();
  }, []);

  const updateModelOptions = data => {
    var options = [];
    data.forEach(function (model, index) {
      options.push({ label: model.experiment_name, value: index });
    });
    setModelOptions(options);
  };

  const selectModel = modelIndex => {
    updateSelectedModel(models[modelIndex]);
    clearData();
  };

  // Form
  const handleSubmit = () => {
    clearData();

    updateIsLoadingData(true);

    // get user input
    var cleanedMoleculeInput = moleculeInput.trim();

    var molblockData;
    var mol;
    var smiles;

    if (moleculeType === 'molblock') {
      molblockData = cleanedMoleculeInput;
      mol = Molecule.fromMolfile(molblockData);
      smiles = mol.toSmiles();
    } else {
      smiles = cleanedMoleculeInput;
      mol = Molecule.fromSmiles(smiles);
      molblockData = mol.toMolfile();
    }

    const currentChemdaslData = { ...chemdaslData, molblock: molblockData, mol: mol, smiles: smiles };
    setChemdaslData(currentChemdaslData);

    var data = predictionRequestBody(moleculeType, smiles, molblockData);

    predictMolblockDesign(data, selectedModel.model_uuid, currentChemdaslData);
  };

  const predictionRequestBody = (type, smiles, molblockData) => {
    // A few notes on how to build the datastructure:
    // The server does not accept smiles AND molblock data. So it has to be one of the two
    // depending on what prediction is being made.
    return {
      stats_and_meta_file: `/deploy/chemdasl_models/${selectedModel.experiment_name}/db/${selectedModel.experiment_name}.json`,
      drop_duplicate_smiles: true,
      svf_mode: type === 'molblock' ? true : false,
      molecules: [
        {
          molId: 'molecule_0001',
          smiles: type === 'smiles' ? smiles : '',
          molblock: type === 'molblock' ? molblockData : '',
        },
      ],
    };
  };

  const resetIsLoading = () => {
    updateIsLoadingData(false);
  };

  // Predict design
  const predictMolblockDesign = (molbock, model_uuid, currentChemdaslData) => {
    const url = CHEMDASL_BACKEND_URL + '/' + model_uuid + '/predict';

    fetch(url, { method: 'POST', headers: getAuthHeader(), body: JSON.stringify(molbock) })
      .then(response => {
        return response.json();
      })
      .then(data => {
        // predictions received from ChemDASL
        // console.log("Received predictions from ChemDASL:");
        // console.log(data);
        updateIsLoadingData(false);
        if (Object.hasOwn(data, 'error')) {
          setErrorMessage(data['error']);
        } else {
          updateSmilesDrawer(data, currentChemdaslData);
        }
      })
      .catch(error => {
        setErrorMessage(error.message);
      });
  };

  const updateSmilesDrawer = (inputData, currentChemdaslData) => {
    var pred = inputData[0]['predictions'];

    const newResults = { ...currentChemdaslData, predictions: pred };
    setChemdaslData(newResults);
    setErrorMessage('');
  };

  // reformatting of the data
  const predictionRows = Object.entries(chemdaslData.predictions ?? {}).map(([key, value]) => (
    <tr key={key}>
      <td className="chemdasl-table-header">{key}</td>
      <td>{value}</td>
    </tr>
  ));

  return (
    <div className="chemdasl">
      <h2>1. Select model</h2>

      {Object.keys(selectedModel).length > 0 && (
        <div>
          <div className="model-selector">
            <Select
              className="model-selector"
              options={modelOptions}
              onChange={value => {
                selectModel(value);
              }}
              defaultValue={0}
            />
          </div>

          <h3>Model Information</h3>
          <div>
            <table className="chemdasl-table">
              <tbody key={selectedModel.model_uuid}>
                <tr>
                  <td className="chemdasl-table-header">Model UUID</td>
                  <td>{selectedModel?.model_uuid}</td>
                </tr>
                <tr>
                  <td className="chemdasl-table-header">Experiment Name</td>
                  <td>{selectedModel?.experiment_name}</td>
                </tr>
                <tr>
                  <td className="chemdasl-table-header">Binary Targets</td>
                  <td>{selectedModel?.binary_targets}</td>
                </tr>
                <tr>
                  <td className="chemdasl-table-header">Regression Targets</td>
                  <td>{selectedModel?.regression_targets}</td>
                </tr>
                <tr>
                  <td className="chemdasl-table-header">Run Name</td>
                  <td>{selectedModel?.run_name}</td>
                </tr>
                <tr>
                  <td className="chemdasl-table-header">Training Duration</td>
                  <td>{selectedModel?.training_duration}</td>
                </tr>
                <tr>
                  <td className="chemdasl-table-header">Trainind End Time</td>
                  <td>{selectedModel?.training_end_time}</td>
                </tr>
                <tr>
                  <td className="chemdasl-table-header">Status</td>
                  <td>{selectedModel?.status}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
      {models?.length == 0 && <p>Please wait while model data is being loaded...</p>}

      {models?.length > 0 && (
        <div>
          <h2>2. Enter molecule data</h2>

          <p>Input format is dependent on the model type.</p>

          {errorMessage.length > 0 && (
            <div>
              <Alert type="error" message="Error" description={errorMessage} closable onClose={clearData} />
              <br></br>
            </div>
          )}

          <Tooltip
            title="The application will automatically try to determine the molecule input type. Click to override."
            placement="right"
            trigger="hover"
          >
            <Radio.Group
              options={moleculeInputTypeOptions}
              value={moleculeType}
              onChange={selectMoleculeType}
              optionType="button"
              buttonStyle={manualMoleculeType ? 'solid' : ''}
            ></Radio.Group>
          </Tooltip>

          <div className="molecule-input">
            <TextArea
              type="text"
              placeholder="Enter data here"
              autoSize={{ minRows: 16 }}
              allowClear
              onChange={onMoleculeInputChange}
            />
          </div>

          {!isLoadingData && (
            <div>
              <h2>3. Submit for prediction</h2>
              <Form name="molblock-form" onFinish={handleSubmit} autoComplete="off">
                <Form.Item>
                  <Button type="primary" htmlType="submit">
                    Predict {moleculeType}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}
          {isLoadingData && (
            <div>
              <Tooltip title="Tap to reset." placement="right" trigger="hover">
                <Spin onClick={resetIsLoading} />
              </Tooltip>
            </div>
          )}
        </div>
      )}

      {chemdaslData.smiles.length > 0 && (
        <div className="chemdasl-results">
          <h2>Results</h2>
          <PreviewSmiles className="chemdasl-preview-smiles" data={chemdaslData} />

          <h2>Predictions</h2>
          {chemdaslData.predictions && (
            <table>
              <tbody>{predictionRows}</tbody>
            </table>
          )}
          {Object.keys(chemdaslData.predictions).length == 0 && errorMessage.length == 0 && (
            <div>
              <Spin />
              <p> Loading data...</p>
            </div>
          )}
          {errorMessage.length > 0 && <p>No predictions available.</p>}

          <h2>Molblock</h2>
          <pre>{chemdaslData.molblock.toString()}</pre>
        </div>
      )}
    </div>
  );
};
